<template>
  <StyledButton>
    <i class="fa-solid fa-arrow-down"></i>
  </StyledButton>    
</template>

<script>
import styled from 'vue-styled-components';

const StyledButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 2px solid #0398fc;
  background-color: transparent;
  color: #0398fc;
  cursor: pointer;
  animation: fadeIn 2s;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  i {
    font-size 3rem;
  }

  &&:hover {
    animation: none;
    transform: scale(1.1);
  }

  &&:active {
    transform: scale(0.95);
  }
`;

export default {
  name: 'NavButton',
  components: {
    StyledButton  
  },
  props: {
    elementId: String
  } 
}
</script>
