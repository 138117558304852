<template>
  <div id="app">
    <Container>
      <div id="name">
        <span>Tom Coupe</span>.
      </div>
      <div id="desc">
        Full Stack Web Developer
      </div>

      <NavButton v-if="showButton"/>
    </Container>
  </div>
</template>

<script>
import styled from 'vue-styled-components';

import NavButton from './components/form/NavButton.vue'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-size: 2rem;
  flex-direction: column;
  font-family: 'Roboto Mono';

  #name {
    font-size: 2.4rem;
    color: #9b6eff;
  }

  #desc {
    font-size: 2.4rem;
    color: royalblue;
  }
`;

export default {
  name: 'App',
  components: {
    Container,
    NavButton
  },
  data() {
    return {
      showButton: false
    }
  },
  mounted() {
    const self = this;

    setTimeout(function() {
      self.showButton = true;
    }, 4000)
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@700&display=swap');

  body {
    background: linear-gradient(-45deg, #ffffff, #b8b8b8, #4d4d4d, #454545);
    background-size: 400% 400%;
    animation: gradient-bg 4s ease;
    height: 100vh;
  }

  @keyframes gradient-bg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
</style>
